@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
    height: 100vh;
    height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}

.h-device-full {
    height: 100vh;
    height: -webkit-fill-available;
}

.invalid-guess {
    animation: shake .5s linear;
}
/* also need keyframes and -moz-keyframes */
@keyframes shake {
    8%, 41% {
        transform: translateX(-10px);
    }
    25%, 58% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-5px);
    }
    92% {
        transform: translateX(5px);
    }
    0%, 100% {
        transform: translateX(0);
    }
}

.pop {
    animation-name: PopIn;
    animation-duration: 100ms;
}
.inactive {
    animation-name: FlipFull;
    animation-duration: 400ms;
}
.i0.inactive {
    transition-delay: 200ms;
}
.i1.inactive {
    animation-delay: 200ms;
    transition-delay: 400ms;
}
.i2.inactive {
    animation-delay: 400ms;
    transition-delay: 600ms;
}
.i3.inactive {
    animation-delay: 600ms;
    transition-delay: 800ms;
}
.i4.inactive {
    animation-delay: 800ms;
    transition-delay: 1000ms;
}




@keyframes PopIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    40% {
        transform: scale(1.1);
        opacity: 1;
    }
}
.tile[data-animation='flip-in'] {
    animation-name: FlipIn;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
}
@keyframes FlipIn {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(-90deg);
    }
}
.tile[data-animation='flip-out'] {
    animation-name: FlipOut;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
}
@keyframes FlipOut {
    0% {
        transform: rotateX(-90deg);
    }
    100% {
        transform: rotateX(0);
    }
}

@keyframes FlipFull {

    0% {
        transform: rotateX(0);
    }
    50% {
        transform: rotateX(-90deg);
    }
    100% {
        transform: rotateX(0);
        border-width: 0;
    }

}